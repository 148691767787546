import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
  FlagIcon,
  ShoppingCartIcon,
} from "@heroicons/react/20/solid";
import { Paragraph } from "../components/Paragraph";
import { HeadingTwo } from "../components/HeadingTwo";
import { Icon } from "@iconify/react";
import { getThemeStyles } from "../modules/_themeStyles";

const features = [
  {
    name: "Law Firms",
    description:
      "Commodo nec sagittis tortor mauris sed. Turpis tortor quis scelerisque diam id accumsan nullam tempus. Pulvinar etiam lacus volutpat eu. Phasellus praesent ligula sit faucibus.",
    href: "#",
    icon: "ri-group-fill",
  },
  {
    name: "Insurance Carriers",
    description:
      "Pellentesque enim a commodo malesuada turpis eleifend risus. Facilisis donec placerat sapien consequat tempor fermentum nibh.",
    href: "#",
    icon: "ri:shield-user-fill",
  },
  {
    name: "Corporations",
    description:
      "Commodo nec sagittis tortor mauris sed. Turpis tortor quis scelerisque diam id accumsan nullam tempus. Pulvinar etiam lacus volutpat eu. Phasellus praesent ligula sit faucibus.",
    href: "#",
    icon: "ri:building-2-fill",
  },
  // {
  //   name: "Service Providers",
  //   description:
  //     "Pellentesque sit elit congue ante nec amet. Dolor aenean curabitur viverra suspendisse iaculis eget. Nec mollis placerat ultricies euismod ut condimentum.",
  //   href: "#",
  //   icon: "ri:shake-hands-fill",
  //   // <Icon icon="ri:shake-hands-fill" />
  // },
  // {
  //   name: "Go fishing",
  //   description:
  //     "Commodo nec sagittis tortor mauris sed. Turpis tortor quis scelerisque diam id accumsan nullam tempus. Pulvinar etiam lacus volutpat eu. Phasellus praesent ligula sit faucibus.",
  //   href: "#",
  //   icon: CloudArrowUpIcon,
  // },
  // {
  //   name: "Take a vacation",
  //   description:
  //     "Pellentesque enim a commodo malesuada turpis eleifend risus. Facilisis donec placerat sapien consequat tempor fermentum nibh.",
  //   href: "#",
  //   icon: LockClosedIcon,
  // },
  // {
  //   name: "Find more work",
  //   description:
  //     "Pellentesque sit elit congue ante nec amet. Dolor aenean curabitur viverra suspendisse iaculis eget. Nec mollis placerat ultricies euismod ut condimentum.",
  //   href: "#",
  //   icon: ArrowPathIcon,
  // },
];

export default function SectionDivider({
  title,
  description,
  variant,
  bgColor,
  textSize,
  children,
}) {
  const {
    backgroundColor,
    gridCols,
    titleTextColor,
    headerTextColor,
    descriptionTextColor,
    featureHeaderTextColor,
    featureDescriptionTextColor,
  } = getThemeStyles(variant, bgColor);
  return (
    <>
      <div className={`  ${backgroundColor} pb-6 pt-12`}>
        <div className="mx-auto max-w-6xl px-6 lg:px-8">
          <div className="mx-auto max-w-6xl text-center">
            <p
              className={`mt-2 ${
                textSize == "md" ? " text-lg " : " text-3xl "
              }  font-semibold tracking-tight ${headerTextColor} sm:text-4xl`}
            >
              {title}
            </p>
            {children ? (
              <div
                className={`mt-6 text-xl leading-8 ${descriptionTextColor} max-w-none`}
              >
                {children}
              </div>
            ) : (
              <div
                className={`mt-6 text-xl leading-8 ${descriptionTextColor} `}
              >
                {description}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
