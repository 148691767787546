import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./charts/ChartjsConfig";
import ToastNotification from "./components/ToastNotification";
// import "./css/style.scss";

import { _auth } from "./modules/_auth";

function App() {
  const [showNotification, setShowNotification] = useState(false);
  const location = useLocation();
  let profile = _auth.getUserProfile();
  profile.role = "user";
  _auth.setUserProfile(profile);
  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  //useEffect to show toast message

  return (
    <>
      {/* <ToastNotification show={true} /> */}

      <div className="App">
        <header className="App-header">
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div>
    </>
  );
}

export default App;
