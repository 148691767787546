/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useEffect } from "react";
import { useRef } from "react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import Fade from "react-reveal/Fade";
import { Icon } from "@iconify/react";
import Logo from "../images/dsulogo.jpg";
import HeroImage from "../images/hero-cover1.jpg";
import cmapScreenshot1 from "../images/screenshot-breachlink-templates.png";
import cmapScreenshot2 from "../images/Screenshot-Compare.png";
import cmapScreenshot3 from "../images/screenshot-breachlink-comments.png";
import cmapScreenshot4 from "../images/screenshot-breachlink-marketplace.png";

//vendor logos
import epiq from "../images/logos/vendors/epiq.png";
import kroll from "../images/logos/vendors/kroll.png";
import integreon from "../images/logos/vendors/newIntegreon.png";
import consilio from "../images/logos/vendors/consilio.png";

import { ChevronDownIcon } from "@heroicons/react/solid";
import { Hero } from "../parts/Hero";
import { FormBanner } from "../parts/FormBanner";
import HubsForm from "../components/Hubsform";
import { Header } from "../parts/Header";
import { ThreePartFeature } from "../parts/ThreePartFeature";
import FeatureGrid from "../parts/FeatureGrid";
import CenterBrandedCTA from "../parts/CenterBrandedCTA";
import TeamSmallPictures from "../parts/TeamSmallPictures";
import StatsLarge from "../parts/StatsLargeDark";
import FeatureList from "../parts/FeatureList";
import CTASplitWithImage from "../parts/CTASplitWithImage";
import SinglePriceWithDetails from "../parts/SinglePriceWithDetails";
import PartnerForm from "../parts/FormBannerPartner";
import ModalBasic from "../components/ModalBasic";
import NotificationBanner from "../components/NotificationBanner";
import PricingModule from "../parts/PricingModule";
import { _pricing } from "../modules/pricing";

import ThreeColumnOnDark from "../parts/ThreeColumnOnDark";
import LogoCloudWithCTA from "../parts/LogoCloudWithCTA";
import BenefitsWithBackground from "../parts/BenefitsWithBackground";
import SmallBanner from "../components/SmallBanner";
import SecondaryFeatures from "../parts/SecondaryFeatures";
import TestimonialsGrid2 from "../parts/TestimonialsGrid2";
import Team from "../parts/Team";
import SectionDivider from "../parts/SectionDivider";
import DevNote from "../components/DevNote";
import { _auth } from "../modules/_auth";
import TestimonialBanner from "../parts/TestimonialBanner";
import { jamey, shawn } from "../constants/testimonialConstants.js";
import {
  CREATE_IN_SECONDS,
  SMALL_BANNER_3,
} from "../constants/smallBannerConstants";
import FAQ from "../parts/FAQ";

// import { exec } from "child_process";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Home() {
  const history = useHistory();
  //set profile role to "user"
  _auth.setUserProfile({ role: "user" });
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const pricing = _pricing.getPricing();
  const setModalOpen = () => {
    setShowModal(false);
  };

  const handleBannerClose = (e) => {
    // e.preventDefault();
    // e.stopPropagation();
    setOpen(false);
  };
  // const hubsForm = useRef();
  const executeScroll = (e) => {
    // alert("scrolling to " + e);
    console.log("scrolling to ", e);
    const element = document.getElementById(e);
    element.scrollIntoView({
      alignToTop: true,
      behavior: "smooth",
      // block: "nearest",
      inline: "start",
    });
  };
  const roles = [
    {
      name: "Cyber Insurers",
      description:
        "For cyber insurers, Breachlink directly improves profitability by reducing claims expenses and lowering loss ratios.",
      icon: "ri:shield-user-fill",
    },
    {
      name: "Insured Businesses",
      description:
        "For businesses with coverage, our platform helps minimize out-of-pocket expenses and reduce the risk of exceeding policy limits.",
      icon: "fa6-solid:building-shield",
    },
    {
      name: "Uninsured Businesses",
      description:
        "For businesses without coverage, every dollar counts, and Breachlink ensures they receive the most cost-effective solutions.",
      icon: "ri:building-2-fill",
    },
    {
      name: "Law Firms",
      description:
        "For Law Firms, Breachlink enables you to fulfill your duty to protect the interests of your clients and panel partners.",
      icon: "octicon:law-16",
    },
  ];

  return (
    <div className="bg-white">
      {open && (
        <NotificationBanner
          className={"sticky top-10 z-100"}
          setOpen={(e) => handleBannerClose(e)}
          showClose={true}
          text={"Ask us about our services!"}
        />
      )}
      <main className="flex flex-col">
        <div id="top">
          <Hero
            // title="Empowering Legal with Simplicity "
            title="Lowering the Cost of Breach Response & Notification"
            subtitle="Breachlink’s competitive bidding platform lowers costs while enhancing efficiency, transparency, and accountability—benefiting cyber insurers, along with both insured and uninsured clients."
            // subtitle="Create bid requests in seconds. Get bids in minutes. Compare them effortlessly."
            image={HeroImage}
            // description="We're enabling Data Breach and eDiscovery by connecting law firms, insurers, and corporations with world-class service providers."
            buttons=""
            executeScroll={executeScroll}
            setShowModal={setShowModal}
          />
        </div>
        <div id="overview">
          <Fade id="breachlinkForEveryone">
            <ThreeColumnOnDark
              title={"Breachlink Benefits Everyone"}
              description={
                "Controlling the cost of a data breach is critical for everyone involved. For insurers, Breachlink reduces claim expenses and lowers loss ratios—directly improving profitability. For insured clients, our platform helps minimize out-of-pocket expenses and reduce the risk of exceeding policy limits. For businesses without coverage, every dollar counts, and Breachlink ensures they receive the most cost-effective solutions. For law firms, Breachlink helps ensure they fulfill their duty to protect their clients' best interests—whether representing an insurer, an insured client, or an uninsured business. Both insurers and law firms can leverage our platform to secure competitive bids, increase transparency, and streamline the procurement process—ensuring compliance while protecting financial outcomes for insured and uninsured clients alike."
              }
              variant={"light"}
              features={[]}
            >
              <div className="items-center text-center justify-center flex flex-col">
                <p className="max-w-4xl">
                  Controlling the cost of a data breach is critical for everyone
                  involved. Breachlink directly benefits cyber insurers and law
                  firms, along with both insured and uninsured clients.
                </p>
                <ul className="grid grid-cols-1 md:grid-cols-2 gap-8 justify-center max-w-6xl text-left mt-4">
                  {roles.map((role) => (
                    <li key={role.name} className="flex gap-x-6 py-5">
                      <div className="flex min-w-0 gap-x-4">
                        <Icon
                          icon={role.icon}
                          className="text-left xl:text-center h-12 w-12 flex text-indigo-400"
                          aria-hidden="true"
                        />
                        <div className="min-w-0 flex-auto">
                          <p className="font-semibold text-gray-900">
                            {role.name}
                          </p>
                          <p className="mt-1 text-gray-500 text-base">
                            {role.description}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <p className="mt-4 max-w-4xl">
                  Both insurers and law firms can leverage our platform to
                  secure competitive bids, increase transparency, and streamline
                  the procurement process—ensuring compliance while protecting
                  financial outcomes for insured and uninsured clients alike.
                </p>
              </div>
            </ThreeColumnOnDark>
          </Fade>
        </div>

        <Fade id="small-banner-stop-wasting-money">
          <div className={`  bg-slate-800 py-12`}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-7xl text-center">
                <p
                  className={`mt-2 text-4xl  font-semibold tracking-tight text-white`}
                >
                  <div className=" text-opacity-80 ">
                    Stop throwing money away by single-sourcing your Breach
                    services.
                  </div>

                  <div className="text-brandGreen-500">
                    Let the Breachlink platform get you the best price.
                  </div>
                </p>
              </div>
            </div>
          </div>
        </Fade>
        <Fade id="logoCloud">
          <div className="relative">
            <LogoCloudWithCTA
              title="
                Access a Marketplace of 50+ Best-in-Class Vendors."
              description="Gain instant access to top-tier breach response providers, specializing in data mining, document review, notification, credit monitoring, and call center services. With Breachlink, you don’t just find vendors—you ensure they compete for your business, guaranteeing the best price from trusted industry leaders. Prefer a specific vendor? No problem—you can invite them to bid too."
              showCTA={false}
              ctaTitle={
                "Our marketplace currently has over 50 world-class service providers and growing every day."
              }
              ctaText={"Check out the full marketplace"}
              logos={[
                {
                  src: epiq,
                  alt: "Epiq",
                  width: 158,
                  height: 48,
                },
                {
                  src: kroll,
                  alt: "Kroll",
                  width: 158,
                  height: 48,
                },
                {
                  src: integreon,
                  alt: "Integreon",
                  // width: 158,
                  // height: 48,
                },
                {
                  src: consilio,
                  alt: "Consilio",
                  width: 158,
                  height: 48,
                },
                // {
                //   src: "https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg",
                //   alt: "Statamic",
                //   width: 158,
                //   height: 48,
                // },
              ]}
              bgColor={"bg-gray-100"}
            />
          </div>
        </Fade>
        {/* <Fade id="small-banner-create-in-seconds">
          <div className={`  bg-slate-800 py-12`}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-7xl text-center">
                <p
                  className={`mt-2 text-4xl  font-semibold tracking-tight text-white`}
                >
                  <div className=" text-opacity-80 ">
                    Stop digging through emails and making spreadsheets.
                  </div>

                  <div className="text-brandGreen-500">
                    Let Breachlink do the work for you.
                  </div>
                </p>
              </div>
            </div>
          </div>
        </Fade> */}

        <Fade id="testimonial-banner">
          <TestimonialBanner person={jamey} />
        </Fade>
        <Fade id="section-insurance-benefits">
          <div id="insurance">
            {" "}
            <BenefitsWithBackground
              id={"insurance-benefits"}
              title={"Insurance Carriers"}
              header={
                "Lower Loss Ratios with Greater Efficiency & Full Transparency."
              }
              orientation="normal"
              learnMoreLink={"#"}
              description={
                "Breachlink helps cyber insurers lower claim costs and optimize loss ratios by introducing real-time competitive bidding. Our platform ensures insurers and their legal partners gain full transparency into vendor selection, reducing administrative overhead and accelerating decision-making."
              }
              data={[
                {
                  name: "Real-Time Monitoring",
                  description:
                    "Get real-time bid visibility across law firms, ensuring faster, more informed decisions.",
                },
                {
                  name: "Cost Efficiency",
                  description:
                    "Reduce administrative overhead and drive cost savings with streamlined vendor selection.",
                },
                {
                  name: "Improved Oversight",
                  description:
                    "Ensure compliance, quality, and full transparency in every bid request.",
                },
                {
                  name: "Collaborate Seamlessly",
                  description:
                    "Work seamlessly with panel counsel in real-time to select the best vendors with confidence.",
                },
              ]}
              layout={"grid"}
              backgroundColor={"bg-gray"}
              gradient={false}
              ctaButton={true}
              ctaButtonText={"More details \u2192"}
              ctaButtonClick={() => {
                console.log("Insurance carriers clicked");
                //navigate to /landing/insurers
                history.push("/landing/insurers");
              }}
            />
          </div>
        </Fade>

        {/* <Fade id="section-corp-benefits">
          <div id="corporate">
            {" "}
            <BenefitsWithBackground
              id={"corp-BidManagement"}
              variant="light"
              title={"Corporate Legal"}
              header={
                "Optimize Your Legal Spend and Vendor Management Processes."
              }
              orientation="reverse"
              description={
                "Whether insured or not, corporations can benefit from Breachlink's robust platform for managing legal and vendor relationships. Use Breachlink like a law firm to streamline your processes or like an insurer to gain real-time insights."
              }
              data={[
                {
                  name: "Efficiency",
                  description:
                    "Manage bids, track statuses, and perform due diligence with an all-in-one platform.",
                },
                {
                  name: "Quality Assurance",
                  description:
                    "Access a network of vetted vendors to ensure high standards.",
                },
                {
                  name: "Cost Management",
                  description:
                    "Optimize your spending by encouraging competitive bidding.",
                },
                {
                  name: "Real-Time Access",
                  description:
                    "Utilize Breachlink Connect to view bids in real-time, saving time and ensuring better decision-making.",
                },
                {
                  name: "Transparency",
                  description:
                    "Maintain clear visibility into the bid process, enhancing compliance and quality control.",
                },
                {
                  name: "Collaboration",
                  description:
                    "Work seamlessly with your legal and procurement teams to select the best vendors.",
                },
              ]}
              backgroundColor={"bg-gray"}
              layout="grid"
              gradient={false}
              backgroundImage={{
                src: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
                alt: "People working on laptops",
              }}
            />
          </div>
        </Fade> */}
        <Fade id="section-law-benefits">
          <div id="lawFirms">
            {" "}
            {/* <DevNote>Not the final layout. Looking at better options</DevNote> */}
            <BenefitsWithBackground
              id={"lawFirms"}
              title={"Law Firms"}
              header={
                "Protect Your Clients & Insurers with Competitive Vendor Selection"
              }
              orientation="reverse"
              variant={"light"}
              description={
                "Law firms play a critical role in safeguarding the financial and legal interests of their insurance partners and clients. Breachlink ensures full transparency, compliance, and seamless collaboration—so every vendor selection is defensible, cost-effective, and audit-ready."
              }
              data={[
                {
                  name: "Transparent Bidding",
                  description:
                    "Ensure fair, transparent bidding that protects your insurers and clients from unnecessary costs.",
                },
                {
                  name: "Audit Readiness",
                  description:
                    "Maintain audit-ready documentation with real-time bid tracking, reporting, and oversight.",
                },
                {
                  name: "Collaboration",
                  description:
                    "Work seamlessly with insurers and legal teams to ensure alignment on vendor selection and pricing.",
                },
                {
                  name: "Vendor Accountability",
                  description:
                    "Ensure your clients and insurance partners receive the best service at the right price.",
                },
                // {
                //   name: "Tracking and Reporting",
                //   description:
                //     "Dashboards and reports for comprehensive bid tracking and analysis.",
                // },
                // {
                //   name: "Vendor Flexibility",
                //   description:
                //     "Enjoy the freedom to choose the best vendor for each project, ensuring you always have the right partner.",
                // },
              ]}
              layout="grid"
              // orientation="left"
              backgroundColor={"bg-white"}
              gradient={false}
            />
          </div>
        </Fade>
        {/* FEATURE SECTION */}
        <div id="features"> </div>
        <SmallBanner
          id="create-in-second"
          title={CREATE_IN_SECONDS.title}
          description={CREATE_IN_SECONDS.description}
        />
        <div id="all-features-div" className="py-12">
          <Fade id="section-features">
            <div id="" className="">
              {" "}
              {/* <DevNote>
                Need better screenshots. Cannot keep all features. Need to
                choose which ones to keep.
              </DevNote>{" "} */}
              <SectionDivider
                title={"Powerful features that put you in control"}
                description={
                  "Explore the unique features of Breachlink tailored for law firms, insurers, and corporations. From bid request templates to bid comparison, Breachlink offers a comprehensive suite of tools to streamline your procurement process."
                }
                variant={"light"}
              />
              <BenefitsWithBackground
                id={"feature-1"}
                variant="light"
                title={"BID REQUEST TEMPLATES"}
                header={"Create bid requests in seconds."}
                orientation="reverse"
                description={
                  "Not sure which questions to ask?  We've done the work for you!  With templates for every use, we provide all the questions needed to get you the bids you need in the most detailed way possible."
                }
                data={[]}
                backgroundColor={"bg-gray"}
                layout="grid"
                gradient={false}
                backgroundImage={{
                  src: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
                  alt: "People working on laptops",
                }}
                featureImage={cmapScreenshot1}
              />
            </div>
          </Fade>
          <Fade id="section-features-2">
            <BenefitsWithBackground
              id={"feature-2"}
              variant="light"
              title={"BID COMPARE"}
              header={"Compare bids side-by-side to make informed decisions."}
              orientation="normal"
              description={
                "Gone are the days of digging through emails and deciphering quotes.  We give you apples to apples bid comparison made easier by our unique approach to vendor bids.  We put in the work to ensure that all bids meet minimum guidelines so you can focus on making the decisions."
              }
              data={[]}
              layout="grid"
              gradient={false}
              backgroundImage={{
                src: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
                alt: "People working on laptops",
              }}
              featureImage={cmapScreenshot2}
              // bgColor={"gray-100"}
            />
          </Fade>
          {/* <Fade id="section-feature-3">
            <BenefitsWithBackground
              id={"corp-BidManagement"}
              variant="light"
              title={"VENDOR MANAGEMENT"}
              header={"Streamline your vendor management"}
              orientation="reverse"
              description={
                "From NDA's to supporting documentation, we make vendor management a breeze.  Need to update your requests. Check.  Need to message everyone?  Check.  Need to notify vendors they lost? Check. Breachlink makes it easy to manage your vendors."
              }
              data={[]}
              bg-color={"bg-gray-200"}
              layout="grid"
              gradient={false}
              backgroundImage={{
                src: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
                alt: "People working on laptops",
              }}
              featureImage={cmapScreenshot3}
            />
          </Fade> */}
          {/* <Fade id="section-features-0">
            <BenefitsWithBackground
              id={"feature-0-realtime-bid-monitoring"}
              variant="light"
              title={"BID MONITORING"}
              header={"Real-Time Bid Monitoring."}
              orientation="normal"
              description={
                "Keep track of all your bids in real time, ensuring you never miss a crucial update."
              }
              data={[]}
              layout="grid"
              gradient={false}
              backgroundImage={{
                src: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
                alt: "People working on laptops",
              }}
              featureImage={cmapScreenshot2}
              // bgColor={"gray-100"}
            />
          </Fade> */}
          <Fade id="section-features-00">
            <BenefitsWithBackground
              id={"feature-0-realtime-bid-monitoring"}
              variant="light"
              title={"COLLABORATION"}
              header={"Interactive Q&A"}
              orientation="reverse"
              description={
                "Engage with vendors through a built-in Q&A feature to clarify bids and make informed decisions."
              }
              data={[]}
              layout="grid"
              gradient={false}
              backgroundImage={{
                src: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
                alt: "People working on laptops",
              }}
              featureImage={cmapScreenshot3}
              // bgColor={"gray-100"}
            />
          </Fade>
        </div>
        <div>
          <Fade id="small-banner-create-in-seconds">
            <div className={`  bg-slate-800 py-12`}>
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-7xl text-center">
                  <p
                    className={`mt-2 text-4xl  font-semibold tracking-tight text-white`}
                  >
                    <div className=" text-opacity-80 ">
                      {" "}
                      {SMALL_BANNER_3.title}
                    </div>

                    <div className="text-brandGreen-500">
                      {SMALL_BANNER_3.description}
                    </div>
                  </p>
                </div>
              </div>
            </div>
            {/* <SmallBanner description="Create bid requests in seconds. Get bids in minutes. Compare them effortlessly." /> */}
          </Fade>
        </div>
        {/* <div className="">
          <Fade id="section-marketplace-list">
             <div className="">
              <SectionDivider
                title={"A marketplace beyond Data Breach and eDiscovery."}
                description={
                  "The Breachlink marketplace is designed to connect you with the best service providers in the industry. From preparedness and training to litigation support, our marketplace offers a wide range of categories to meet your needs."
                }
                variant={"light"}
              />
              <FeatureList
                id={"marketplace"}
                title={""}
                header={""} //A marketplace beyond Data Breach and eDiscovery.
                description={""} //Breachlink started with post-data breach service providers.  But we've expanded to include breach preparedness as well as litigation support. Check out just a few of the categories featured in our marketplace:
                features={[
                  {
                    name: "Preparedness & Training",
                    description: "",
                  },
                  {
                    name: "Managed Detection & Response",
                    description: "",
                  },
                  {
                    name: "Forensics & Ransomware",
                    description: "",
                  },
                  {
                    name: "Data Mining & Document Review",
                    description: "",
                  },
                  {
                    name: "Notification & Identity Services",
                    description: "",
                  },
                  {
                    name: "eDiscovery & Litigation Support",
                    description: "",
                  },
                ]}
                variant={"light"}
                // bgColor={"gray-100"}
              />
            
            </div> 
          </Fade>
        </div> */}

        <Fade id="testimonial-banner-shawn">
          <TestimonialBanner person={shawn} />
        </Fade>

        <Fade id="section-split-image-cta">
          <CTASplitWithImage
            title=""
            header="Curious about how Breachlink can help you?"
            description="Signing up is free and easy. Get started today."
            ctaText="Sign up for free"
            executeScroll={executeScroll}
          />
        </Fade>
        <div id="services" className="">
          <Fade id="fade-services">
            <StatsLarge
              id={"services"}
              title={"Quotes at Breachlink"}
              header={"Free Bid Management Service for Law Firms"}
              orientation="reverse"
              variant={"light"}
              description={
                "Ensure fair pricing and vendor accountability for your clients—without the hassle. Breachlink’s complimentary service secures competitive bids from vetted providers on your behalf, ensuring full transparency and compliance with your insurer’s guidelines. No new platform to learn—simply email quotes@breachlink.com with your bid request, and we’ll handle the rest."
              }
              data={[
                {
                  stat: "Save Time",
                  rest: "Ensure cost-effective vendor selection for your clients, while we handle the bidding process.",
                },
                {
                  stat: "Competitive Bids",
                  rest: "Secure fair, market-driven pricing with multiple competitive bids—ensuring your clients receive the best value.",
                },
                {
                  stat: "High-Quality Vendors",
                  rest: "Choose from a vetted network of top-tier service providers, ensuring compliance with insurance panel requirements.",
                },
                {
                  stat: "No Cost to Law Firms",
                  rest: "A zero-cost service for law firms and insurers—our vendors cover the costs, so you can focus on your clients' needs.",
                },
              ]}
              showCTA={true}
              ctaText={"Get started today \u2192"}
              ctaClick={"#contact"}
              layout="grid"
              gradient={false}
              showBackground={false}
            />
          </Fade>
        </div>

        {/* <Fade id="section-testimonials">
          <div id="testimonials">
            <DevNote>
              We need to get some testimonials. Just need a few.{" "}
              <p>This is not the final design for testimonials.</p>
            </DevNote>
            <SectionDivider
              title={"What our customers are saying"}
              description={
                "We work with some amazing people. Here's what they have to say."
              }
              variant="light"
            />
            <TestimonialsGrid2 id="testimonials-component" />
          </div>
        </Fade> */}
        <div id="vendors">
          <Fade id="section-service-providers">
            {/* <DevNote>Content to be updated with latest </DevNote>{" "} */}
            {/* <SectionDivider
              title={"Become a vendor on Breachlink"}
              description={
                "Want to be among the very best in the world?  Get listed in our marketplace and start bidding on jobs"
              }
              variant={"dark"}
            /> */}
            <StatsLarge
              id={"serviceProviders"}
              title={"Service Providers"}
              header={"Join Our Network of Trusted Service Providers"}
              description={
                "Are you a service provider looking to expand your reach and connect with more potential clients? Join Breachlink to showcase your value and grow your business."
              }
              variant={"dark"}
              showCTA={true}
              ctaText={"Join our network"}
              ctaClick={"https://app.breachlink.com/signup"}
              data={[
                {
                  id: 1,
                  stat: "Detailed bidding",
                  emphasis: "",
                  rest: "Break down your bids to show your value in user-friendly detail.",
                },
                {
                  id: 2,
                  stat: "Q&A",
                  emphasis: "",
                  rest: "Ask and answer questions to clarify your bid and win the job.",
                },
                {
                  id: 3,
                  stat: "Be discovered",
                  emphasis: "",
                  rest: "Get in front of the right people, at the right time, every time.",
                },
                {
                  id: 4,
                  stat: "Track your success",
                  emphasis: "",
                  rest: "See how you compare to the competition and improve.",
                },
              ]}
            />
          </Fade>
        </div>
        <Fade id="section-team">
          <div id="team" className="h-full min-h-[800px]">
            {/* <DevNote>
              Will create team profiles. Need to decide who is listed. Link will
              go to new company page.
            </DevNote> */}

            <SectionDivider
              title="Meet the Team Behind Breachlink"
              description="At Breachlink, our mission is to revolutionize the legal
              industry's approach to data breach response and eDiscovery. Meet
              the dedicated team driving innovation and excellence."
              variant="light"
            />
            <Team />
          </div>
        </Fade>

        <Fade id="cta-ready-next-step">
          {" "}
          <CenterBrandedCTA
            text={"Ready to take the next step?"}
            buttonText={"Sign up for free"}
            link={"https://app.breachlink.com/signup"}
            executeScroll={executeScroll}
            bottomText={""}
            variant="dark" //light, brand, dark
          />
        </Fade>
        <Fade id="section-faq">
          <div id="faq">
            <SectionDivider
              title={"Frequency Asked Questions"}
              description={
                "Have a different question and can’t find the answer you’re looking for? Reach out to our support team by sending us an email and we’ll get back to you as soon as we can."
              }
              variant={"light"}
              bgColor={"gray-100"}
            />
            <FAQ scope="home" />
          </div>
        </Fade>

        <Fade id="section-hubspot-section-1">
          <div id="contact" className=" lg:pt-0">
            <HubsForm id="hubsForm" variant={"light"} />
          </div>
        </Fade>
      </main>
      <ModalBasic
        id="partner_modal"
        modalOpen={showModal}
        setModalOpen={setModalOpen}
        title="Get in touch!"
      >
        <PartnerForm id="partnerForm" />
      </ModalBasic>
      {/* ------------------------------------ --------- ----------------------------------*/}
      {/* ------------------------------------ GRAVEYARD ----------------------------------*/}
      {/* ------------------------------------ --------- ----------------------------------*/}
      {/* <SmallBanner
          description={
            "At Breachlink, we're transforming the data breach response and eDiscovery processes for law firms, insurance companies, corporations, and service providers. Our platform is designed to optimize costs, enhance quality, and save you precious time by streamlining the procurement process."
          }
        /> */}
      {/* <CenterBrandedCTA
          variant="light"
          text={"Convinced? We thought you would be."}
          buttonText={"Sign up for free"}
          link={"https://app.breachlink.com/signup"}
          executeScroll={executeScroll}
          bottomText={"If you're not, contact us and let us convince you!"}
        /> */}
      {/* <SmallBanner
          description={
            "Ready to make security assessments part of your offering? We've got you covered with reseller, whitelabel, and integration partner programs!"
          }
          partner={true}
          executeScroll={executeScroll}
          setShowModal={setShowModal}
        /> */}
      {/* <SinglePriceWithDetails /> */}
      {/* <HubsForm id="hubsForm" /> */}
      {/* <div id="solutions-lawFirms">
          <ThreePartFeature
            title={"Law Firms"}
            header={"Maximize Efficiency and Quality in Your Vendor Management"}
            description={
              "Breachlink streamlines your data breach response, saving you time and money. Compare bids, track statuses, and perform due diligence with ease."
            }
            features={[
              {
                name: "Optimize Costs",
                description:
                  "Encourage competitive bids to achieve the best value for your investments.",
              },
              {
                name: "Improve Quality",
                description:
                  "Access a marketplace of vetted, high-quality vendors.",
              },
              {
                name: "Easy Comparisons",
                description:
                  "Side-by-side bid comparisons to make informed decisions.",
              },
              {
                name: "Tracking and Reporting",
                description:
                  "Dashboards and reports for comprehensive bid tracking and analysis.",
              },
              {
                name: "Vendor Flexibility",
                description:
                  "Enjoy the freedom to choose the best vendor for each project, ensuring you always have the right partner.",
              },
            ]}
          />
        </div> */}
      {/* <Fade id="section-feature-carousel">
          {" "}
          <SecondaryFeatures
            showImages
            title="Notable Features"
            description="Explore some of our features by clicking the feature descriptions."
            images={[]}
          />
        </Fade> */}
      {/* <ModalBasic
        id="client_modal"
        modalOpen={showClientModal}
        setModalOpen={setClientModalOpen}
        title="Join today!"
      >
        <HubsForm id="hubsForm" />
      </ModalBasic> */}
    </div>
  );
}
