// themeStyles.js
export const getThemeStyles = (variant, bgColor) => {
  const backgroundBaseColor = bgColor
    ? bgColor
    : variant === "light"
    ? "white"
    : "slate-800";
  const backgroundColor = "bg-" + backgroundBaseColor;

  const titleTextColor =
    variant === "light" ? "text-indigo-600" : "text-indigo-400";
  const headerTextColor = variant === "light" ? "text-slate-700" : "text-white";
  const descriptionTextColor =
    variant === "light" ? "text-gray-600" : "text-gray-300";
  const featureHeaderTextColor =
    variant === "light" ? "text-slate-800" : "text-white text-opacity-90";
  const featureDescriptionTextColor =
    variant === "light" ? "text-gray-600" : "text-gray-300";

  return {
    backgroundColor,
    titleTextColor,
    headerTextColor,
    descriptionTextColor,
    featureHeaderTextColor,
    featureDescriptionTextColor,
  };
};
