let baseUrl = "";
let appUrl = "";
console.log("REACT_APP_NODE_ENV", process.env.REACT_APP_NODE_ENV);
if (process.env.REACT_APP_NODE_ENV === "development") {
  baseUrl = "http://localhost:3001/api/";
  appUrl = "http://localhost:7000";
} else if (process.env.REACT_APP_NODE_ENV === "production") {
  baseUrl = "https://web-api.breachlink.com/api/";
  appUrl = "https://app.breachlink.com";
} else if (process.env.REACT_APP_NODE_ENV === "beta") {
  baseUrl = "https://beta-web-api.breachlink.com/api/";
  appUrl = "https://beta.breachlink.com";
}
console.info("CURRENT ENVIRONMENT: ", process.env.REACT_APP_NODE_ENV);
console.info("CURRENT BASE URL: ", baseUrl);
console.info("CURRENT APP URL: ", appUrl);
export { baseUrl, appUrl };
