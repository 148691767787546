export const faqs = [
  {
    question: "What is Breachlink?",
    answer:
      "Breachlink is a competitive bidding platform that helps cyber insurers and their law firms lower breach response costs by allowing vendors to compete for their business transparently.",
    scope: ["home", "insurers", "lawfirms"],
  },
  {
    question: "How does Breachlink help insurers lower claim costs?",
    answer:
      "By introducing competition and transparency to the procurement process, Breachlink ensures that insurers get the best price from top-rated vendors instead of overpaying through single-sourced contracts.",
    scope: ["insurers"],
  },
  {
    question: "Who can use Breachlink?",
    answer:
      "Cyber insurance carriers and law firms can use Breachlink to streamline vendor selection and optimize breach response costs.",
    scope: ["home", "insurers", "lawfirms"],
  },
  {
    question: "How do I mantain visbility if my lawfirms use Breachlink?",
    answer:
      "When creating a bid request on your behalf, the law firm can invite you to view the details of the request, enduring that you stay in control.",
    scope: ["insurers"],
  },
  {
    question: "How many vendors are available on Breachlink?",
    answer:
      "Our marketplace features 50+ world-class breach response providers across multiple service areas, including data mining, document review, notification, credit monitoring, and call-center support.",
    scope: ["home", "insurers", "lawfirms"],
  },
  {
    question: "What if my preferred vendor isn’t listed?",
    answer:
      "You can invite any vendor of your choice to bid on your request, ensuring you always have access to your trusted providers while still benefiting from competitive pricing.",
    scope: ["home", "insurers", "lawfirms"],
  },
  {
    question:
      "How long does it take to set up an account and create my first bid request?",
    answer:
      "The entire process—from signing up and setting your password to submitting your first request—takes under 5 minutes.",
    scope: ["home", "insurers", "lawfirms"],
  },
  {
    question: "How does the bidding process work?",
    answer:
      "1️⃣ Create a bid request. 2️⃣ Invite vendors (or let Breachlink suggest vendors). 3️⃣ Receive competitive bids in real-time. 4️⃣ Compare bids apples-to-apples and select the best provider.",
    scope: ["home", "insurers", "lawfirms"],
  },
  {
    question: "Can I set specific requirements when requesting bids?",
    answer:
      "Yes, you can define scope, compliance needs, SLAs, and pricing preferences to ensure vendors meet your exact requirements.",
    scope: ["home", "insurers", "lawfirms"],
  },
  {
    question: "How do I know I’m getting the best deal?",
    answer:
      "Breachlink ensures full pricing transparency and standardized bid comparisons, making it easy to see exactly what each vendor is offering so you can make an informed decision.",
    scope: ["home", "insurers", "lawfirms"],
  },
  {
    question: "How much does it cost to use Breachlink?",
    answer:
      "Breachlink is free for insurers and their legal teams. You only pay for the vendor services you select—there are no hidden fees or platform charges.",
    scope: ["home", "insurers", "lawfirms"],
  },
  {
    question: "Is my data secure?",
    answer:
      "Yes! Breachlink uses industry-standard encryption and strict access controls to ensure all bid requests and vendor communications remain confidential and secure.",
    scope: ["home", "insurers", "lawfirms"],
  },

  {
    question: "What if I need help using the platform?",
    answer:
      "We offer dedicated support to assist with onboarding, bid creation, and vendor selection. Contact us anytime at support@breachlink.com.",
    scope: ["home", "insurers", "lawfirms"],
  },
  {
    question: "How do I get started?",
    answer:
      "Click 'Sign Up for Free' and create your first bid request in minutes!",
    scope: ["home", "insurers", "lawfirms"],
  },
];
