import { faqs } from "../constants/faqConstants";

export default function FAQ({ scope }) {
  return (
    <div className="bg-gray-100">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
        <div className="mt-0">
          <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10">
            {faqs.map(
              (faq) =>
                faq.scope.includes(scope) && (
                  <div key={faq.id}>
                    <dt className="text-base/7 font-semibold text-gray-900">
                      {faq.question}
                    </dt>
                    <dd className="mt-2 text-base/7 text-gray-600">
                      {faq.answer}
                    </dd>
                  </div>
                )
            )}
          </dl>
        </div>
      </div>
    </div>
  );
}
