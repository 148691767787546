/* This example requires Tailwind CSS v2.0+ */
// import { ExternalLinkIcon } from "@heroicons/react/solid";

import { HeadingTwo } from "../components/HeadingTwo";

export default function CTASplitWithImage({
  title,
  header,
  description,
  features,
  ctaText,
  url,
}) {
  return (
    <div className="relative bg-gray-800">
      <div className="bg-slate-800 h-56 bg-slate-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img
          className="w-full h-full object-cover opacity-30"
          // src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&blend=6366F1&sat=-100&blend-mode=multiply"
          // src="https://unsplash.com/photos/a-group-of-cubes-that-are-on-a-black-surface-T9rKvI3N0NM"
          // src="https://unsplash.com/photos/T9rKvI3N0NM/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8NXx8bmV0d29ya3xlbnwwfDB8fHwxNzIwNDAxODU2fDI&force=true&w=1920"
          src="https://unsplash.com/photos/376KN_ISplE/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8MTd8fG1lZXRpbmd8ZW58MHwwfHx8MTcyMDQwMjA2Nnwy&force=true&w=1920"
          alt=""
        />{" "}
        <div
          aria-hidden="true"
          className={`absolute top-0 h-32 bg-gradient-to-b xl:bg-gradient-to-l from-slate-800 xl:inset-y-0 xl:right-0 xl:h-full xl:w-32`}
        />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <HeadingTwo variant={"light"}>{header}</HeadingTwo>

          <p className="mt-3 text-lg text-gray-300">
            {description && description}
          </p>
          <div className="mt-8 w-full">
            <div className="inline-flex rounded-md shadow w-full">
              <a
                href={url ? url : "http://app.breachlink.com/signup"}
                className="w-1/2 hover:w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-full text-white text-opacity-80 bg-brandGreen-500 hover:bg-brandGreen-600  transition-all duration-300"
              >
                {ctaText}
                {/* <ExternalLinkIcon
                  className="-mr-1 ml-3 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                /> */}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
