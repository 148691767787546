import jameyDavidson from "../images/headshots/jameyDavidson.jpg";
import shawnFord from "../images/headshots/shawnFord.jpg";

export const jamey = {
  name: "Jamey Davidson",
  company: "Partner, O'Hagan Meyer",
  quote:
    "Breachlink has helped me drive cost savings for my insurance partners and clients while making my firm more efficient. By introducing competition, transparency, and automation, they are changing the game. For too long, breach procurement has been labor-intensive, with limited choices, days of manual effort, and no accountability. Now, Breachlink literally saves me days every month—and saves my insurance partners and clients significant money.",
  image: jameyDavidson,
};

export const shawn = {
  name: "Shawn Ford",
  company:
    "Partner | Incident Response (Lead) Lawyer & Breach Coach, Ceiba Law",
  quote:
    "I can't believe how easy it is. You've put cost savings on autopilot and the results have been tremendous.",
  image: shawnFord,
};
