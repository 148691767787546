import React from "react";
import Fade from "react-reveal/Fade";
export default function SmallBanner({ id, title, description }) {
  return (
    <div>
      <Fade id={id}>
        <div className={`  bg-slate-800 py-12`}>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-7xl text-center">
              <p
                className={`mt-2 text-4xl  font-semibold tracking-tight text-white`}
              >
                <div className=" text-opacity-80 ">{title}</div>

                <div className="text-brandGreen-500">{description}</div>
              </p>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}
