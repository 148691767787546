import Button from "../elements/Button";

export default function GrowingButton({
  ctaLink,
  ctaText,
  ctaFunction,
  ctaColor,
  shape,
}) {
  return (
    <div className="items-center  w-full">
      <div className="justify-center  text-center inline-flex rounded-md  w-full">
        {ctaLink && (
          <a
            href={ctaLink}
            className={`cursor-pointer w-full hover:w-full md:w-1/2 lg:w-1/3 lg:hover:w-1/2 inline-flex items-center justify-center px-5 py-3  text-base font-medium  text-white bg-brandGreen-500 hover:bg-brandGreen-600 transition-all duration-300 ${
              shape && shape === "rounded" ? "rounded-full" : "rounded-md"
            }`}
          >
            {ctaText}
          </a>
        )}
        {ctaFunction && (
          <a
            //   href={ctaLink}
            onClick={ctaFunction}
            className={`cursor-pointer w-full hover:w-full md:w-1/2 lg:w-1/3 lg:hover:w-1/2 inline-flex items-center justify-center px-5 py-3  text-base font-medium  text-white bg-brandGreen-500 hover:bg-brandGreen-600 transition-all duration-300 ${
              shape && shape === "rounded" ? "rounded-full" : "rounded-md"
            }`}
          >
            {ctaText}
          </a>
        )}
      </div>
    </div>
  );
}
