import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import Logo from "../images/BreachConnectLogoBlue.png";
import LightLogo from "../images/BreachConnectLogoSidebar.png";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { FeatureFlag } from "../components/FeatureFlag";
import { set } from "date-fns";
import { current } from "tailwindcss/colors";
import FlyoutMenu from "../components/FlyoutMenu";
import Button from "../elements/Button";
import HeaderLinks from "./HeaderLinks";
import Sidebar from "../partials/Sidebar";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export function Header({
  navigation,
  isScrolled,
  sidebarOpen,
  setSidebarOpen,
  setScrollTo,
  scrollTo,
  handleNavClick,
  executeScroll,
  currentLink,
  setCurrentLink,
  handleOpenSidebarClick,
  ...props
}) {
  const location = useLocation();
  // const [sidebarOpen, setSidebarOpen] = useState(false);
  // const [currentLink, setCurrentLink] = useState("home");
  const { pathname } = location;
  const history = useHistory();
  const [textColor, setTextColor] = useState("text-white");
  const [linkColor, setLinkColor] = useState("text-white opacity-90");
  const [currentLinkColor, setCurrentLinkColor] = useState("text-white");
  const [navbarVariant, setNavbarVariant] = useState("dark");
  const [navbarStyle, setNavbarStyle] = useState(
    "bg-slate-800 bg-opacity-0 text-white text-opacity-90"
  );
  const [defaultColor, setDefaultColor] = useState("text-white");
  const [activeColor, setActiveColor] = useState("text-indigo-300");
  const [hoverColor, setHoverColor] = useState("text-indigo-300");
  // const [scrollTo, setScrollTo] = useState(null);

  // const handleOpenSidebarClick = (e, value) => {
  //   e.preventDefault();
  //   setSidebarOpen(e, value);
  // };
  // const setSidebarExpanded = (e) => {
  //   e.preventDefault();
  //   setSidebarOpen(true);
  // };
  // const setSidebarClosed = (e) => {
  //   // e.preventDefault();
  //   setSidebarOpen(false);
  // };

  useEffect(() => {
    console.log("IsScrolled", isScrolled);
    let variant = "";
    let style = " ";
    let textColor = "";
    let linkColor = "";
    let currentLinkColor = "";
    let defaultColor = "";
    let activeColor = "";
    let hoverColor = "";
    console.log("pathname", pathname);
    if (isScrolled) {
      variant = "light";
    } else {
      console.log("Setting variant to dark");
      variant = "dark";
    }

    if (variant === "dark") {
      style = "bg-slate-800 bg-opacity-0 text-white text-opacity-50";
      defaultColor = "text-white";
      activeColor = "text-indigo-300";
      hoverColor = "text-indigo-300";
      textColor = "text-white";
      linkColor = "text-white opacity-80";
      currentLinkColor = "text-white";
      console.log("Dark", style);
    } else {
      style = "bg-white text-slate-800 text-opacity-90";
      defaultColor = "text-slate-700";
      activeColor = "text-indigo-600";
      hoverColor = "text-indigo-600";
      textColor = "text-slate-800";
      linkColor = "text-slate-800";
      currentLinkColor = "text-indigo-600";
      console.log("Light", style);
    }

    setNavbarStyle(style);
    setNavbarVariant(variant);
    setDefaultColor(defaultColor);
    setActiveColor(activeColor);
    setHoverColor(hoverColor);
    setTextColor(textColor);
    setLinkColor(linkColor);
    setCurrentLinkColor(currentLinkColor);
  }, [isScrolled, pathname]);

  useEffect(() => {
    console.log("pathname/currentLink", pathname, currentLink);
  }, [pathname, currentLink]);

  //ChatGPT suggested using this method to scroll to an element

  // const executeScroll = (id) => {
  //   console.log("scrolling to ", id);
  //   const element = document.getElementById(id);
  //   if (element) {
  //     element.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //       inline: "start",
  //     });
  //   } else {
  //     console.error("Element not found:", id);
  //   }
  // };
  // useEffect(() => {
  //   if (scrollTo) {
  //     executeScroll(scrollTo);
  //   }
  // }, [scrollTo]);

  // const handleNavClick = (id) => {
  //   setScrollTo(id);
  // };

  const getLinkColor = (pathname1, currentLink, scrollToLocation, id) => {
    console.log(
      "getLinkColor",
      pathname,
      currentLink,
      scrollToLocation,
      scrollTo,
      id
    );
    if (pathname === currentLink && scrollToLocation === scrollTo) {
      return activeColor + " hover:" + hoverColor + "";
    } else if (pathname === currentLink) {
      return defaultColor;
    } else {
      return linkColor;
    }
  };

  return (
    <>
      <header
        className={`whitespace-nowrap sticky top-0 z-20 transition-colors duration-500 ${navbarStyle} `}
      >
        {/* <Popover className="relative "> */}
        <div className="flex  items-center justify-between lg:justify-start  mx-auto px-4 py-4 sm:px-6  md:space-x-10 ">
          {/* <div className="flex inline "> */}
          <div className="flex flex-row items-center">
            <img
              // width="50"
              className="mr-3  h-6 "
              src={isScrolled ? Logo : LightLogo}
            />

            <span
              className={`hidden sm:inline-flex  md:mr-2 lg:m-auto lg:inline-flex text-xl lg:text-2xl 2xl:text-3xl m-auto font-medium ${textColor}`}
            >
              Breachlink{" "}
            </span>
          </div>
          {/* </div> */}

          <div
            id="header-links"
            className="h-full space-x-4 xl:space-x-8 hidden md:flex md:flex-1  items-center justify-start lg:w-0"
          >
            {" "}
            {navigation.map((item) => {
              if (item && item.desktop && item.desktop === true)
                if (item.options) {
                  return (
                    <div className="">
                      <FlyoutMenu
                        key={item.name}
                        title={item.name}
                        options={item.options}
                        isScrolled={isScrolled}
                        onClick={(scrollTo, href) =>
                          handleNavClick(scrollTo, href)
                        }
                      />
                    </div>
                  );
                } else {
                  return (
                    <div className="">
                      <button
                        name={item.name}
                        onClick={() => handleNavClick(item.scrollTo, item.href)}
                        className={`h-full   font-semibold leading-6 ${getLinkColor(
                          item.href,
                          item.href,
                          item.scrollTo,
                          item.id
                        )} `}
                      >
                        {item.name}
                      </button>
                    </div>
                  );
                }
            })}
          </div>
          <div
            id="buttons"
            className="mr-8 hidden lg:flex items-center justify-end sm:shrink-1 lg:w-0"
          >
            <div className="ml-8 hidden xl:flex">
              <a
                href="https://app.breachlink.com/signup"
                className="px-8 ml-8 whitespace-nowrap items-center justify-center bg-brandGreen-500 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-indigo-700 hover:to-indigo-700"
              >
                Join Free
              </a>
            </div>
            <div className="ml-8 hidden lg:flex">
              <a
                href="https://app.breachlink.com"
                className={`whitespace-nowrap text-base font-medium px-8 rounded-md ${
                  isScrolled
                    ? "btn text-slate-800 border border-slate-800 hover:text-slate-700"
                    : "btn border border-white text-white"
                } `}
              >
                Login
              </a>
            </div>
          </div>
          <div
            id="hamburger-menu"
            className={`mr-1  md:hidden flex items-center justify-end  lg:w-0`}
          >
            {/* Hamburger button */}
            <button
              className={`${
                isScrolled ? " text-gray-800 " : " text-white "
              }   mr-3`}
              aria-controls="sidebar"
              aria-expanded={false} //sidebarOpen
              onClick={(e) => handleOpenSidebarClick(e, !sidebarOpen)}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
            <div className="flex m-auto lg:inline xl:inline 2xl:inline 3xl:inline 4xl:inline ">
              {/* <h2 className="text-xl font-semibold ">Breachlink</h2> */}

              {/* <img
                src={Logo}
                alt="Logo"
                // className="hidden sm:hidden md:inline-flex lg:inline-flex xl:inline-flex "
                style={{ height: "60px" }}
              /> */}
            </div>

            <div className="hidden sm:hidden md:hidden lg:flex xl:inline 2xl:inline 3xl:inline 4xl:inline ">
              {/* <HeaderLinks
                showHome={showHome}
                align="left"
                navigation={navigation}
              /> */}
            </div>
          </div>
        </div>

        {/* <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/workflow-mark-indigo-600-to-indigo-600.svg"
                        alt="Workflow"
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-6">
                    <nav className="grid grid-cols-1 gap-7">
                      {solutions.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-indigo-600 to-indigo-600 text-white">
                            <item.icon className="h-6 w-6" aria-hidden="true" />
                          </div>
                          <div className="ml-4 text-base font-medium text-slate-800">
                            {item.name}
                          </div>
                        </a>
                      ))}
                    </nav>
                  </div>
                </div>
                <div className="py-6 px-5">
                  <div className="grid grid-cols-2 gap-4">
                    <a
                      href="#"
                      className="text-base font-medium text-slate-800 hover:text-gray-700"
                    >
                      Pricing
                    </a>
                    <a
                      href="#"
                      className="text-base font-medium text-slate-800 hover:text-gray-700"
                    >
                      Partners
                    </a>
                    <a
                      href="#"
                      className="text-base font-medium text-slate-800 hover:text-gray-700"
                    >
                      Company
                    </a>
                  </div>
                  <div className="mt-6">
                    <a
                      href="#"
                      className="w-full flex items-center justify-center bg-gradient-to-r from-indigo-600 to-indigo-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-indigo-700 hover:to-indigo-700"
                    >
                      Sign up
                    </a>
                    <p className="mt-6 text-center text-base font-medium text-gray-500">
                      Existing customer?
                      <a href="#" className="text-slate-800">
                        Sign in
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition> */}
        {/* </Popover> */}
      </header>
    </>
  );
}
