import React from "react";
import SectionDivider from "./SectionDivider";

export default function LogoCloudWithCTA({
  logos,
  title,
  description,
  showCTA,
  ctaText,
  ctaTitle,
  ctaClick,
  bgColor,
}) {
  return (
    <div className={`${bgColor || " bg-gray-100 "} pb-12 sm:pb-24`}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8 ">
        <SectionDivider
          title={title}
          description={description}
          variant={"light"}
          bgColor={bgColor}
        />
        {/* <h2 className="py-8 text-center text-4xl font-semibold leading-8 text-slate-700">
          {title}
        </h2>
        <p className={`mt-6 text-xl leading-8  max-w-none`}>{description}</p> */}
      </div>
      <div className="mt-8 mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-4">
        {logos.map((logo, index) => (
          <img
            key={index}
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={logo.src}
            alt={logo.alt}
            width={158}
            height={48}
          />
        ))}
      </div>
      {showCTA && (
        <div className="mt-16 flex justify-center ">
          <p className="relative rounded-full bg-gray-50 px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-slate-800/5">
            <span className="hidden md:inline mr-6">{ctaTitle}</span>
            <button
              onClick={() => ctaClick()}
              className="font-semibold text-indigo-600"
            >
              <span className="absolute inset-0" aria-hidden="true" /> {ctaText}{" "}
              <span aria-hidden="true">&rarr;</span>
            </button>
          </p>
        </div>
      )}
    </div>
  );
}
