/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GlobalStateContext } from "../store/GlobalState";
import { appUrl } from "../config/urlRoutes";
import jwtDecode from "jwt-decode";
import Fade from "react-reveal/Fade";

import HeroImage from "../images/hero-cover1.jpg";

//vendor logos
import epiq from "../images/logos/vendors/epiq.png";
import kroll from "../images/logos/vendors/kroll.png";
import integreon from "../images/logos/vendors/newIntegreon.png";
import consilio from "../images/logos/vendors/consilio.png";

import HubsForm from "../components/Hubsform";

import CTASplitWithImage from "../parts/CTASplitWithImage";

import PartnerForm from "../parts/FormBannerPartner";
import ModalBasic from "../components/ModalBasic";
import NotificationBanner from "../components/NotificationBanner";

import { _pricing } from "../modules/pricing";

import ThreeColumn from "../parts/ThreeColumn";
import FAQ from "../parts/FAQ";
import LogoCloudWithCTA from "../parts/LogoCloudWithCTA";

import SectionDivider from "../parts/SectionDivider";

import { _auth } from "../modules/_auth";
import TestimonialBanner from "../parts/TestimonialBanner";

import FadedImageBackground from "../parts/FadedImageBackground";
import ContentGrid from "../parts/ContentGrid";
import Steppers from "../components/Steppers";

import {
  SMALL_BANNER_3,
  STOP_OVERPAYING,
} from "../constants/smallBannerConstants";
import { jamey, shawn } from "../constants/testimonialConstants.js";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NetDLanding() {
  const GlobalState = React.useContext(GlobalStateContext);
  const history = useHistory();
  const location = useLocation();
  const { ref, token } = useParams();
  //set profile role to "user"
  _auth.setUserProfile({ role: "user" });
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [referrerName, setReferrerName] = useState("");
  const [refId, setRefId] = useState(null);
  const [profile, setProfile] = useState({});
  const [signUpUrl, setSignUpUrl] = useState(null);
  const pricing = _pricing.getPricing();
  const setModalOpen = () => {
    setShowModal(false);
  };

  useEffect(() => {
    let references = [
      { id: "insurers", name: null },
      { id: "142ac1d7c84b447595aab4ee", name: "NetDiligence" },
      { id: "142ac1d7c84b447595aab4ef", name: "Your vendor" },
    ];
    if (!ref || !references.find((r) => r.id === ref)) {
      history.push("/");
    } else {
      let referr = references.find((r) => r.id === ref).name;
      setReferrerName(referr);
      GlobalState.updateState("referrerName", referr);
      // referr && referr !== null && GlobalState.updateState("signUpUrl", ref);
      setRefId(ref);
      let url = `${appUrl}/signup/`;

      if (referr && referr !== null) {
        url = url + ref;
      }
      setSignUpUrl(url);

      let decoded;

      let currentTime;
      let expired;
      if (token && token !== "undefined" && token !== null) {
        decoded = jwtDecode(token);
        console.log("decoded", decoded);
        currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          expired = true;
        }
      } else {
        expired = true;
        decoded = { email: "", name: "", first_name: "", last_name: "" };
      }

      if (expired) {
        console.log("expired");
        setProfile(null);
      } else {
        console.log("not expired");
        console.log("using Profile", decoded);
        setProfile(decoded);
      }
    }
  }, [ref, history]);

  const handleBannerClose = (e) => {
    // e.preventDefault();
    // e.stopPropagation();
    setOpen(false);
  };
  // const hubsForm = useRef();
  const executeScroll = (e) => {
    // alert("scrolling to " + e);
    console.log("scrolling to ", e);
    const element = document.getElementById(e);
    element.scrollIntoView({
      alignToTop: true,
      behavior: "smooth",
      // block: "nearest",
      inline: "start",
    });
  };

  return (
    <div className=" bg-white ">
      {profile && (
        <div className="z-50  absolute top-0 left-0  bg-brandGreen-500  p-2 w-full lg:w-[50%] xl:w-full ">
          <div className="pb-2">
            <div className="text-xl font-semibold">TEST ONLY</div>
          </div>
          <div className="flex items-center  ">
            {profile && profile.name} {" " + profile && profile.email}{" "}
            {profile && profile.company}
          </div>
        </div>
      )}
      {open && (
        <NotificationBanner
          className={"sticky top-10 z-100"}
          setOpen={(e) => handleBannerClose(e)}
          showClose={true}
          text={"Ask us about our services!"}
        />
      )}
      <main className="flex flex-col">
        <Fade id="section-hero">
          <div id="hero">
            {" "}
            <FadedImageBackground
              id={"insurance-benefits"}
              title={"Insurance Carriers"}
              header={"Enhance Efficiency and Transparency in Bid Management."}
              orientation="normal"
              description={
                "Breachlink helps insurers oversee the bid process with greater efficiency and transparency. By ensuring attorneys use Breachlink, you gain real-time insights into bids and can make more informed decisions faster."
              }
              data={[
                {
                  name: "Real-Time Monitoring",
                  description:
                    "With Breachlink Connect, view bids across law firms as they come in, saving time and enhancing decision-making.",
                },
                {
                  name: "Cost Efficiency",
                  description:
                    "Streamline the process to reduce administrative overhead and focus on selecting the best vendors.",
                },
                {
                  name: "Improved Oversight",
                  description:
                    "Gain transparency into the bidding process, ensuring compliance and quality.",
                },
                {
                  name: "Collaborate Seamlessly",
                  description:
                    "Collaborate in real-time with your panel counsel to select the best vendors.",
                },
              ]}
              layout={"grid"}
              bgColor={"slate-800"}
              backgroundColor={"bg-gray"}
              gradient={false}
              showPartnerCard={referrerName ? true : false}
            >
              <ContentGrid
                title="Cut Claim Costs & Lower Loss Ratios with Breachlink"
                subtitle={`${
                  referrerName
                    ? referrerName +
                      " has partnered with Breachlink to help cyber insurers lower claim costs with a free, competitive bidding platform—ensuring vendors compete for your business, not the other way around."
                    : " Breachlink helps cyber insurers lower claim costs with a free, competitive bidding platform—ensuring vendors compete for your business, not the other way around."
                } `}
                image={HeroImage}
                buttons=""
                executeScroll={executeScroll}
                setShowModal={setShowModal}
                profile={profile ? profile : null}
                referrerName={referrerName}
                refId={refId ? refId : null}
                url={signUpUrl}
              />
            </FadedImageBackground>
          </div>
        </Fade>
        <Fade id="section-legal-benefits">
          <div id="overview">
            {" "}
            <Fade id="breachlinkForEveryone">
              <ThreeColumn
                title={"Breachlink for the Cyber Insurance Industry"}
                description={
                  "Breachlink helps cyber insurers lower claim costs by bringing competition, transparency, and efficiency to breach response procurement. Our platform streamlines bid management, ensuring vendors compete for your business—reducing costs, improving oversight, and accelerating response times. With Breachlink, insurers and their law firms gain full visibility and control over the vendor selection process."
                }
                variant={"light"}
                features={[
                  {
                    name: "Lower Claim Costs",
                    description:
                      "Encourage competitive bidding among your existing vendors, ensuring the most cost-effective breach response every time.",
                    href: "#",
                    icon: "streamline:graph-bar-decrease",
                  },
                  {
                    name: "Streamline Vendor Selection",
                    description:
                      "Automate bid management to reduce administrative overhead and accelerate breach response, cutting claim cycle times.",
                    href: "#",
                    icon: "fa:gears",
                  },
                  {
                    name: "Increase Transparency & Control",
                    description:
                      "Gain full visibility into vendor pricing, performance, and compliance, ensuring you always choose the best vendor for every claim.",
                    href: "#",
                    icon: "mynaui:users-group",
                  },
                ]}
              />
            </Fade>
          </div>
        </Fade>
        <Fade id="small-banner-stop-digging">
          <div className={`  bg-slate-800 py-12`}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-7xl text-center">
                <p
                  className={`mt-2 text-4xl  font-semibold tracking-normal text-white`}
                >
                  <div className=" text-opacity-80 ">
                    {STOP_OVERPAYING.title}
                  </div>

                  <div className="text-brandGreen-500">
                    {STOP_OVERPAYING.description}
                  </div>
                </p>
              </div>
            </div>
          </div>
          {/* <SmallBanner description="Create bid requests in seconds. Get bids in minutes. Compare them effortlessly." /> */}
        </Fade>

        <Fade id="testimonial-banner-jamey">
          <TestimonialBanner person={jamey} />
        </Fade>
        <Fade id="three-ways">
          <div className="w-full bg-gray-100 px-12 py-12">
            <SectionDivider
              title={"Three ways to streamline your bid management process"}
              description={
                "We provide three effortless ways to get the job done, and no matter which one you choose, you have complete real-time transparency and control over the process."
              }
              variant={"light"}
              bgColor={"gray-100"}
            />
            <Steppers
              type="icons"
              steps={[
                {
                  name: "Let us do it for you",
                  description:
                    "Our full-service no-cost bid management program gives you the best of both worlds.  We manage the process and communications with the vendors; you select the winner and negotiate the contract.",
                  status: "complete",
                  icon: "healthicons:group-discussion-meetingx3",
                },
                {
                  name: "Require your law firms use Breachlink",
                  description:
                    "Significantly reduce your cost to receive pricing and your claim costs by requiring your law firms to use Breachlink.  You'll have real-time transparency and control over the process.",
                  // "Requiring your law firms to use Breachlink not only ensures you have real-time transparency and control, but could significantly reduce your cost to receive pricing.",
                  status: "complete",
                  icon: "mdi:briefcase-arrow-left-right",
                },
                {
                  name: "Manage it yourself",
                  description:
                    "Create a request, invite vendors, and manage the process from start to finish.  You can save time and money, maintain control, and ensure things are done exactly as you want.",
                  status: "complete",
                  icon: "fluent:handshake-20-filled",
                },
              ]}
            />
          </div>
        </Fade>

        <Fade id="small-banner-create-in-seconds">
          <div className={`  bg-slate-800 py-12`}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-7xl text-center">
                <p
                  className={`mt-2 text-4xl  font-semibold tracking-tight text-white`}
                >
                  <div className=" text-opacity-80 ">
                    {" "}
                    Create bid requests in seconds. Get bids in minutes.{" "}
                  </div>

                  <div className="text-brandGreen-500">
                    Compare them effortlessly.
                  </div>
                </p>
              </div>
            </div>
          </div>
          {/* <SmallBanner description="Create bid requests in seconds. Get bids in minutes. Compare them effortlessly." /> */}
        </Fade>
        <Fade id="section-1-2-3">
          <div className="w-full bg-gray-100 px-12 py-12">
            <SectionDivider
              title={"Easy as 1, 2, 3"}
              description={
                "Getting the best price for breach response and notification has never been easier."
              }
              variant={"light"}
              bgColor={"gray-100"}
            />
            <Steppers
              type="numbered"
              steps={[
                {
                  name: "Create your request",
                  description:
                    "Create a bid request in seconds and invite vendors to compete for your business.",
                  status: "complete",
                },
                {
                  name: "Receive bids",
                  description:
                    "Get real-time notifications when vendors submit bids, allowing instant comparisons.",
                  status: "complete",
                },
                {
                  name: "Select a vendor",
                  description:
                    "Choose the best-fit vendor with confidence, ensuring cost savings and transparency.",
                  status: "complete",
                },
              ]}
            />
          </div>
        </Fade>
        <Fade id="testimonial-banner-shawn">
          <TestimonialBanner person={shawn} />
        </Fade>
        <Fade id="small-banner-stop-digging">
          <div className={`  bg-slate-800 py-12`}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-7xl text-center">
                <p
                  className={`mt-2 text-4xl  font-semibold tracking-normal text-white`}
                >
                  <div className=" text-opacity-80 ">
                    {SMALL_BANNER_3.title}
                  </div>

                  <div className="text-brandGreen-500">
                    {SMALL_BANNER_3.description}
                  </div>
                </p>
              </div>
            </div>
          </div>
          {/* <SmallBanner description="Create bid requests in seconds. Get bids in minutes. Compare them effortlessly." /> */}
        </Fade>
        <Fade id="logoCloud">
          <div className="relative">
            <LogoCloudWithCTA
              title="
               Access a Marketplace of 50+ Best-in-Class Vendors"
              description="Gain instant access to top-tier breach response providers, specializing in data mining, document review, notification, credit monitoring, and call center services. With Breachlink, you don’t just find vendors—you ensure they compete for your business, guaranteeing the best price from trusted industry leaders. Prefer a specific vendor? No problem—you can invite them to bid too."
              showCTA={false}
              ctaTitle={
                "Our marketplace currently has over 50 world-class service providers and gorowing every day."
              }
              ctaText={"Check out the full marketplace"}
              logos={[
                {
                  src: epiq,
                  alt: "Epiq",
                  width: 158,
                  height: 48,
                },
                {
                  src: kroll,
                  alt: "Kroll",
                  width: 158,
                  height: 48,
                },
                {
                  src: integreon,
                  alt: "Integreon",
                  // width: 158,
                  // height: 48,
                },
                {
                  src: consilio,
                  alt: "Consilio",
                  width: 158,
                  height: 48,
                },
                // {
                //   src: "https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg",
                //   alt: "Statamic",
                //   width: 158,
                //   height: 48,
                // },
              ]}
              bgColor={"bg-white"}
            />
          </div>
        </Fade>
        {/* <Fade id="testimonial-banner">
          <TestimonialBanner />
        </Fade> */}

        <div id="solutions"> </div>

        <Fade id="section-split-image-cta">
          <CTASplitWithImage
            title=""
            header="Slash Claim Costs in Minutes!"
            description="Sign up and submit your first bid request in under 5 minutes. Let vendors compete for your business—at no cost to you."
            ctaText="Sign up for free"
            executeScroll={executeScroll}
            url={signUpUrl}
          />
        </Fade>

        <Fade id="faqFade">
          <div id="faq">
            <SectionDivider
              title={"Frequency Asked Questions"}
              description={
                "Have a different question and can’t find the answer you’re looking for? Reach out to our support team by sending us an email and we’ll get back to you as soon as we can."
              }
              variant={"light"}
              bgColor={"gray-100"}
            />
            <FAQ scope="insurers" />
          </div>
        </Fade>
        <Fade id="section-hubspot-section-1">
          <div id="contact" className=" lg:pt-0">
            <HubsForm id="insurerContactForm" variant={"light"} refId={refId} />
          </div>
        </Fade>
      </main>
      <ModalBasic
        id="partner_modal"
        modalOpen={showModal}
        setModalOpen={setModalOpen}
        title="Get in touch!"
      >
        <PartnerForm id="partnerForm" />
      </ModalBasic>
    </div>
  );
}
