import React from "react";
import BreachlinkLogo from "../images/BreachConnectLogoBlue.png";
import NetDLogo from "../images/logos/netD.svg";
import { appUrl } from "../config/urlRoutes";

const ContentGrid = ({
  title,
  subtitle,
  description,
  executeScroll,
  profile,
  referrerName,
  refId,
  url,
}) => {
  return (
    <div
      id="content-grid"
      className=" h-full flex grow-1 justify-left items-left content-left"
    >
      <div
        id="main-hero"
        className="flex flex-col my-auto justify-center relative px-4 lg:px-8  mx-auto"
      >
        <h1
          className="font-light text-4xl sm:text-5xl md:text-5xl lg:text-6xl max-w-6xl 
        text-left  text-white mb-5"
        >
          {title}
        </h1>
        <h2 className="h2 font-normal leading-8 text-lg md:text-2xl lg:3xl max-w-4xl text-left tracking-normal text-white mb-5">
          {profile && "Hi " + profile.name + ", "} {subtitle}
        </h2>

        {description && description !== "" && (
          <p className="text-left text-base leading-8 tracking-normal text-white max-w-3xl mt-4">
            {description}
          </p>
        )}
        <div className="mt-10 max-w-sm  sm:max-w-none flex flex-col md:flex-row space-y-6 justify-left md:space-x-6">
          <div className="flex justify-between  sm:space-y-0  inline-grid grid-cols-1 md:grid-cols-2 gap-5">
            <a
              onClick={(e) => {
                e.stopPropagation();
                executeScroll("contact");
              }}
              className="hover:cursor-pointer bg-opacity-10 backdrop-blur-sm w-full flex items-center justify-center px-4 py-3 border border-white text-base font-medium text-white hover:text-slate-800 rounded-md shadow-sm bg-transparent hover:bg-white sm:px-8"
            >
              Learn More
            </a>
            <a
              href={url}
              onClick={(e) => {
                e.stopPropagation();
                executeScroll("vetBanner");
              }}
              className="bg-opacity-90 w-full flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium text-white rounded-md shadow-sm bg-brandGreen-500 hover:bg-brandGreen-700 sm:px-8"
            >
              Join for Free
            </a>
          </div>
          {/* <div className="flex justify-left space-y-4 sm:space-y-0  sm:inline-grid sm:grid-cols-1 sm:gap-5">
            <a
              href="https://app.breachlink.com/signup"
              onClick={(e) => {
                e.stopPropagation();
                executeScroll("vetBanner");
              }}
              className="bg-opacity-90 w-full flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium text-slate-900 rounded-md shadow-sm bg-brandGreen-500 hover:bg-brandGreen-600 sm:px-8"
            >
              Join Breachlink for Free
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ContentGrid;
