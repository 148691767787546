// smallBannerConstants.js

// Option 1 (More Direct & Urgent)
// Stop Overpaying for Breach Response.
// Let vendors compete for your business and get the best price with Breachlink.

// Option 2 (Reinforcing Transparency & Competition)
// Why pay more for breach services?
// Breachlink drives competition, transparency, and cost savings—so you always get the best deal.

// Option 3 (Emphasizing Industry Norms & Change)
// Single-sourcing breach services is costing you.
// Let Breachlink introduce competitive bidding to lower claim costs and improve efficiency.

// Option 4 (Stronger Call-to-Action)
// Stop overpaying for breach response.
// Put vendors in competition and secure the best price—only with Breachlink.

export const STOP_OVERPAYING = {
  title: "Stop Overpaying for Breach Services.",
  description: "Significantly reduce your claim costs with Breachlink.",
};
export const SMALL_BANNER_2 = {
  title: "Why pay more for breach services?",
  description:
    "Breachlink drives competition, transparency, and cost savings—so you always get the best deal.",
};

export const SMALL_BANNER_3 = {
  title: "Single-sourcing breach services is costing you.",
  description: "Lower claim costs and improve efficiency with Breachlink.",
};

export const SMALL_BANNER_4 = {
  title: "Stop overpaying for breach response.",
  description:
    "Put vendors in competition and secure the best price—only with Breachlink.",
};

export const SMALL_BANNER_SPREADSHEETS = {
  title: "Tired of managing breach services in spreadsheets?",
  description:
    "Breachlink streamlines your workflow and centralizes all your breach response needs.",
};

export const CREATE_IN_SECONDS = {
  title: "Create bid requests in seconds. Get bids in minutes.",
  description: "Compare them effortlessly.",
};
